<template>
  <div class="files-panel">
    <p>{{ $t('filesPanel.content.0') }}</p>
    <p>{{ $t('filesPanel.content.1') }}</p>
    <SharedFiles
      ref="sharedFiles"
      :appointment-id="appointmentId"
      :practitioner-id="practitionerId"
      @upload="handleUploadedFile"
    />
  </div>
</template>

<script>
  import SectionTitle from '../../SectionTitle';
  import SharedFiles from '../../SharedFiles';
  import Draggabilly from "draggabilly";

  export default {
    name: 'FilesPanel',
    components: { SharedFiles, SectionTitle },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      practitionerId: {
        type: String,
        required: true,
      },
    },
    async mounted() {
      this.$store.dispatch('sdkAddEvent', { name: '#UPLOADED_FILE', callback: this.onUploadedFile })
        let draggableElems = document.querySelectorAll('.draggable');
        let draggies = []
        for ( let draggableElem of draggableElems ) {
            let draggie = new Draggabilly( draggableElem, {
                containment: true,
            });
            draggies.push( draggie );
        }
    },
    beforeDestroy() {
      this.$store.dispatch('sdkRemoveEvent', { name: '#UPLOADED_FILE', callback: this.onUploadedFile })
    },
    methods: {
      onUploadedFile() {
        this.$refs.sharedFiles.reloadFiles();
      },
      handleUploadedFile() {
        this.$store.dispatch('sdkEmitRemoteEvent', { name: '#UPLOADED_FILE' });
      },
    },
  };
</script>
