<!--
Emitted events:
- toggleFilesPanel
-->

<template>
  <div class="control-bar">
    <div class="control" :class="{ muted: microphoneMuted }" @click="toggleMicrophone">
      <FontAwesomeIcon :icon="microphoneIcon" />
    </div>
    <div class="control" :class="{ muted: cameraMuted }" @click="toggleCamera">
      <FontAwesomeIcon :icon="cameraIcon" />
    </div>
    <div class="control" @click="$emit('toggleFilesPanel')">
      <FontAwesomeIcon :icon="$icons.filesPanel" />
    </div>
    <div class="control control--telemetry" @click="$emit('toggleTelemetryPanel')">
      <FontAwesomeIcon :icon="$icons.doc2u" />
    </div>
    <div class="control">
      <ChatIcon small />
    </div>
  </div>
</template>

<script>
  import ChatIcon from '@/components/Chat/ChatIcon';
  export default {
    name: 'ControlBar',
    components: { ChatIcon },
    computed: {
      microphoneMuted() {
        return !this.$store.state.sdk.local.audio.enabled;
      },
      cameraMuted() {
        return !this.$store.state.sdk.local.video.enabled;
      },
      microphoneIcon() {
        return this.microphoneMuted
          ? this.$icons.encounterMicrophoneToggleDisabled
          : this.$icons.encounterMicrophoneToggle;
      },
      cameraIcon() {
        return this.cameraMuted
          ? this.$icons.encounterCameraToggleDisabled
          : this.$icons.encounterCameraToggle;
      },
    },
    methods: {
      toggleMicrophone() {
        this.$store.dispatch('sdkToggleAudioDevice');
      },
      toggleCamera() {
        this.$store.dispatch('sdkToggleVideoDevice');
      },
      openChat() {
        this.$store.dispatch('chatOpen');
      },
    }
  };
</script>

<style lang="scss" scoped>
  .control-bar {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 2rem;
    background-color: #1F1F1FA0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #F1F1F1;
    border-radius: 3.5rem;
    width: 100%;
    max-width: 580px;
    @include onMobile {
      justify-content: space-around;
      width: 65%;
      max-width: 490px;
    }
  }

  .control {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2.5rem;
    @include onMobile {
      width: 0;
    }

    &:hover {
      background-color: #1F1F1F80;
    }

    &.muted {
      color: $cancelButtonColor;
    }
  }

  .encounter-stop {
    color: #FFFFFF80;

    &:not(.disabled) {
      color: #FFFFFF;
      background-color: $cancelButtonColor;
      font-size: 2rem;

      &:hover {
        background-color: lighten($cancelButtonColor, 5%);
      }
    }
  }
</style>
