<template>
  <div class="payment-interface">
    <SectionTitle center>
      <span> {{ $t('paymentInterface.title') }} </span>
    </SectionTitle>
    <CreditCardsPictograms />
    <div v-if="!paymentAccepted" class="error">
      <p> {{ $t('paymentInterface.error.1') }} </p>
      <p> {{ $t('paymentInterface.error.2') }} </p>
    </div>
    <div v-else class="container">
      <p class="description">
        {{ $t('paymentInterface.description') }}
      </p>
      <CreditCardAddForm required @close="$router.back()" @create="reloadPaymentMethods" />
    </div>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import ButtonGreen from '../ButtonGreen';
  import FormWidget from '../../components/Form/FormWidget';
  import CreditCardsPictograms from '@/components/CreditCardsPictograms';
  import CreditCards from '@/components/CreditCards';
  import CreditCardAddForm from '@/components/Form/CreditCardAddForm';

  export default {
    name: 'PaymentInterface',
    components: { CreditCardAddForm, CreditCards, CreditCardsPictograms, ButtonGreen, SectionTitle, FormWidget },
    props: {
      paymentAccepted: {
        required: true,
      },
    },
    methods: {
      reloadPaymentMethods() {
        this.$store.dispatch('paymentMethodsFetchAll');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .payment-interface {
    height: 100%;
    padding: 4rem;
    background-color: #FFFFFF;
  }

  p {
    font-weight: 200;
    line-height: 3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .container {
    font-size: 1.5rem;
    max-width: 60rem;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .credit-cards {
    max-width: 50rem;
    margin: 0 auto;
  }

  .payment-informations {
    margin-left: 1.6rem;
    border-left: 0.1rem solid $lightGrey;

    .price {
      font-weight: bold;
      width: 15rem;
      margin-top: 2rem;
      padding: 1.2rem 3.6rem 1.2rem 1.5rem;
      color: #fff;
      border-radius: 0 0.5rem 0.5rem 0;
      background: #F46868;

      small {
        position: absolute;
        margin-bottom: 15px;
        margin-left: 2px;
      }
    }

    p {
      //line-height: 13px;
      padding-left: 12px;
      text-align: left;
    }
  }

  .buttons {
    margin-top: 2rem;
    text-align: center;
  }

  .error {
    font-size: 1.5rem;
    max-width: 80rem;
    margin: 1rem auto;
    padding: 2rem;
    text-align: center;
    color: white;
    border-radius: 0.8rem;
    background-color: $errorColor;

    p {
      font-weight: 400;
      margin: 0;
    }
  }
</style>
