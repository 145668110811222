<!--
Emitted events:
- start
-->
<template>
  <div class="summary-interface">
    <div class="container">
      <div class="summary">
        <SectionTitle> {{ $t('summaryInterface.title') }} </SectionTitle>
        <div class="content">
          <PractitionerCard :practitioner="practitioner" />

          <p class="start-at">
            {{ appointment.startAt | date('dddd Do MMMM YYYY') }}
            - {{ appointment.startAt | date('HH[h]mm') }}
          </p>

          <p>{{ appointment.product.channel | channel }}</p>

          <p v-html="$t('summaryInterface.advise')"/>
        </div>

        <p class="amount-row">{{ $t('summaryInterface.amount') }}<span class="amount">{{ totalPrice | money }}</span></p>

        <p class="content">
          <small v-html="$t('summaryInterface.amount-info')"></small>
        </p>
        <portal :disabled="$media.desktop" to="mobile-submit">
          <form @submit.prevent="handleSubmit">
            <ButtonRed
              v-if="!selectedPaymentMethodId"
              block
              class="action"
              disabled
            >
              {{ $t('summaryInterface.means-payment') }}
            </ButtonRed>
            <ButtonGreen
              v-else
              :busy="submitting"
              :icon="$icons.chevronRight"
              block
              class="action"
              type="submit"
            >
              {{ $t('summaryInterface.join-appointment') }}
            </ButtonGreen>
          </form>
        </portal>
      </div>
      <div class="payment">
        <div v-if="!paymentAccepted" class="error">
          <p>{{ $t('summaryInterface.payment-error.1') }}</p>
          <p>{{ $t('summaryInterface.payment-error.2') }}</p>
        </div>
        <div v-else>
          <GroupTitle :icon="$icons.creditCard">{{ $t('summaryInterface.choose-payment') }}</GroupTitle>
          <p class="text_red">
            {{ $t('summaryInterface.payment-info.1') }}
          </p>
          <p class="text_red">
            {{ $t('summaryInterface.payment-info.2') }}
          </p>
          <CreditCards v-model="selectedPaymentMethodId" selectable @select="handleSelectPaymentMethod" />
        </div>
      </div>
      <portal-target name="mobile-submit" />
    </div>
  </div>
</template>

<script>
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonRed from '@/components/ButtonRed.vue';
  import CreditCards from '@/components/CreditCards';
  import GroupTitle from '@/components/GroupTitle';
  import PractitionerCard from '@/components/PractitionerCard';
  import SectionTitle from '@/components/SectionTitle';
  import { stripe } from '@/helpers/stripe';
  import { sumBy } from 'lodash-es';

  export default {
    name: 'SummaryInterface',
    components: {
      ButtonGreen,
      ButtonRed,
      CreditCards,
      GroupTitle,
      PractitionerCard,
      SectionTitle,
    },
    props: {
      encounter: {
        required: true,
      },
      paymentAccepted: {
        required: true,
      },
    },
    data() {
      return {
        selectedPaymentMethodId: null,
        paymentError: null,
        submitting: false,
      };
    },
    computed: {
      appointment() {
        return this.encounter.appointment;
      },
      practitioner() {
        return this.encounter.practitioner;
      },
      totalPrice() {
        let sum = sumBy(this.encounter.products, (product => product.unitPrice));
        if(this.encounter.appointment&&this.encounter.appointment.markup) {
          sum += this.encounter.appointment.markup;
        }
        if(this.encounter.appointment&&this.encounter.appointment.markupPartner) {
          sum += this.encounter.appointment.markupPartner;
        }
        return sum;
      },
      busy() {
        return this.submitting || this.$store.state.encounters.isAuthorizing;
      },
    },
    methods: {
      handleSelectPaymentMethod(selectedPaymentMethodId) {
        this.selectedPaymentMethodId = selectedPaymentMethodId;
      },
      async authorize() {
        await this.$store.dispatch('encountersAuthorize', {
          id: this.encounter.id,
          stripePaymentMethodId: this.selectedPaymentMethodId,
          updateCurrent: true,
        });

        if (this.encounter.authorized) {
          this.$emit('start');
          return true;
        }

        return false;
      },
      async handleSubmit() {
        try {
          this.submitting = true;

          if (await this.authorize()) {
            return;
          }

          if (this.encounter.paymentStatus === 'need_3ds') {
            const result = await stripe.handleCardAction(this.encounter.paymentValidationSecret);

            if (result.error) {
              return this.$addError(result.error.message);
            }

            if (await this.authorize()) {
              return;
            }
          }

          await this.$addError(this.$t('summaryInterface.authError'));
        } catch (e) {
          if (e.response?.status !== 402) {
            this.paymentError = this.$t('summaryInterface.paymentError');
          } else {
            const response = await e.response.json();

            const stripeErrors = {
              'Your card was declined.': 'Votre carte bancaire a été refusée.',
              'Your card has insufficient funds.': 'Le paiement a été refusé pour cause de fonds insuffisants.',
              'Your card has expired.': 'Votre carte bancaire a expiré.',
              'Your card\'s security code is incorrect.': 'Le cryptogramme (CVC) est incorrect.',
              'An error occurred while processing your card. Try again in a little bit.': 'Une erreur a eu lieu lors du traitement de votre carte. Veuillez réessayer dans quelques minutes.',
              'Your destination account needs to have at least one of the following capabilities enabled: transfers, legacy_payments': 'Erreur avec le compte de votre praticien : contactez-le par téléphone pour lui signaler',
            };

            this.paymentError = stripeErrors[response.message] || this.$t('summaryInterface.paymentReject');
          }

          throw e;
        } finally {
          this.submitting = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .summary-interface {
    height: 100%;
    background-color: #FFFFFF;

    @include onDesktop {
      padding: 4rem;
    }
  }

  p {
    font-weight: 200;
  }

  .start-at {
    font-weight: 400;
    text-transform: capitalize;
  }

  .container {
    font-size: 1.5rem;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1.5rem;

    @include onDesktop {
      display: flex;
    }
  }

  .content {
    padding-right: 6rem;
  }

  .credit-cards {
    max-width: 50rem;
    margin: 0 auto;
  }

  .summary {
    flex: 1;
  }

  .payment {
    flex: 0.7;

    @include onDesktop {
      padding-top: 6rem;
      padding-left: 2rem;
      border-left: 0.1rem solid $lightGrey;
    }

    @include onMobile {
      .credit-cards {
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }

    .error p {
      padding-left: 1.2rem;
      text-align: left;
    }
  }

  .amount-row {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background-color: $lightGrey;

    .amount {
      font-weight: 400;
    }
  }

  .buttons {
    margin-top: 2rem;
    text-align: center;
  }

  .error {
    font-weight: 400;
    padding: 2rem;
    text-align: center;
    color: white;
    border-radius: 0.8rem;
    background-color: $errorColor;
  }

  .action {
    max-width: 38rem;
    margin: 0 auto;
  }

  .text_red {
    color: $errorColor;
    font-weight: 400;
  }
</style>
