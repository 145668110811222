var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-interface" },
    [
      _c("SectionTitle", { attrs: { center: "", light: "" } }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _c("div", { staticClass: "text-wrapper" }, [
        _c(
          "div",
          { staticClass: "error-message" },
          [
            _vm._t("default", [
              _vm._v(" " + _vm._s(_vm.$t("errorInterface.error")) + " ")
            ])
          ],
          2
        )
      ]),
      _vm.errorCode
        ? _c("div", { staticClass: "error-code" }, [
            _vm._v("\n    Code erreur : " + _vm._s(_vm.errorCode) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }