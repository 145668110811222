var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabled
    ? _c(
        "div",
        { staticClass: "media-selector", class: { collapsed: _vm.collapsed } },
        [
          _vm.audioDevices.length > 1
            ? _c(
                "div",
                { staticClass: "media-selector-item" },
                [
                  _c("label", { attrs: { for: "video-device" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("mediaSelector.choose-micro")) + " "
                    )
                  ]),
                  _c("SelectWrapper", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.audioDeviceId,
                            expression: "audioDeviceId"
                          }
                        ],
                        attrs: { id: "audio-device" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.audioDeviceId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.audioDevices, function(audioDevice, index) {
                        return _c(
                          "option",
                          { domProps: { value: audioDevice.deviceId } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  audioDevice.label || "Micro #" + (index + 1)
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.videoDevices.length > 1
            ? _c(
                "div",
                { staticClass: "media-selector-item" },
                [
                  _c("label", { attrs: { for: "video-device" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("mediaSelector.choose-camera")) + " "
                    )
                  ]),
                  _c("SelectWrapper", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.videoDeviceId,
                            expression: "videoDeviceId"
                          }
                        ],
                        attrs: { id: "video-device" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.videoDeviceId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.videoDevices, function(videoDevice, index) {
                        return _c(
                          "option",
                          { domProps: { value: videoDevice.deviceId } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  videoDevice.label || "Camera #" + (index + 1)
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "toggle-icon",
              on: {
                click: function($event) {
                  _vm.collapsed = !_vm.collapsed
                }
              }
            },
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.encounterCameraToggle }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }