<template>
  <div v-if="remoteLost" class="message-overlay">
    <SectionTitle light center>
      {{ $t('mediaMessageOverlay.connection-lost') }}
    </SectionTitle>
    <p>{{ $t('mediaMessageOverlay.reconnecting') }}{{ waitingDots }}</p>
  </div>
  <div v-else-if="cameraMuted && microphoneMuted" class="message-overlay">
    <SectionTitle light center>
      {{ $t('mediaMessageOverlay.local-device.both.muted') }}
    </SectionTitle>
    <p>{{ $t('mediaMessageOverlay.local-device.both.info') }}</p>
  </div>
  <div v-else-if="cameraMuted" class="message-overlay">
    <SectionTitle light center>
      {{ $t('mediaMessageOverlay.local-device.video.muted') }}
    </SectionTitle>
    <p>{{ $t('mediaMessageOverlay.local-device.video.info') }}</p>
  </div>
  <div v-else-if="microphoneMuted" class="message-overlay">
    <SectionTitle light center>
      {{ $t('mediaMessageOverlay.local-device.audio.muted') }}
    </SectionTitle>
    <p>{{ $t('mediaMessageOverlay.local-device.audio.info') }}</p>
  </div>
  <div v-else-if="remoteJoined">
    <div v-if="patientCameraMuted && patientMicrophoneMuted" class="message-overlay">
      <SectionTitle light center>
        {{ $t('mediaMessageOverlay.remote-device.both.muted') }}
      </SectionTitle>
      <p>{{ $t('mediaMessageOverlay.remote-device.both.info') }}</p>
    </div>
    <div v-else-if="patientCameraMuted" class="message-overlay">
      <SectionTitle light center>
        {{ $t('mediaMessageOverlay.remote-device.video.muted') }}
      </SectionTitle>
      <p>{{ $t('mediaMessageOverlay.remote-device.video.info') }}</p>
    </div>
    <div v-else-if="patientMicrophoneMuted" class="message-overlay">
      <SectionTitle light center>
        {{ $t('mediaMessageOverlay.remote-device.audio.muted') }}
      </SectionTitle>
      <p>{{ $t('mediaMessageOverlay.remote-device.audio.info') }}</p>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '../../SectionTitle';

  const { REMOTE_STATUS } = window.PlatformSDK;

  let waitingDotsIntervalId;

  export default {
    name: 'MediaMessageOverlay',
    components: { SectionTitle },
    data() {
      return {
        waitingDots: '...',
      };
    },
    computed: {
      remoteJoined() {
        return this.$store.state.sdk.remote.status === REMOTE_STATUS.JOINED;
      },
      remoteLost() {
        return this.$store.state.sdk.remote.status === REMOTE_STATUS.CONNECTION_LOST;
      },
      microphoneMuted() {
        return !this.$store.state.sdk.local.audio.enabled;
      },
      cameraMuted() {
        return !this.$store.state.sdk.local.video.enabled;
      },
      patientMicrophoneMuted() {
        return !this.$store.state.sdk.remote.audio.enabled;
      },
      patientCameraMuted() {
        return !this.$store.state.sdk.remote.video.enabled;
      },
    },
    watch: {
      remoteLost(remoteLost) {
        if (remoteLost) {
          waitingDotsIntervalId = this.$setInterval(() => {
            this.waitingDots += '.';
          }, 2000);
        } else {
          window.clearInterval(waitingDotsIntervalId);
          this.waitingDots = '...';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .message-overlay {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFFFFF;
    width: 100%;
    padding: 4rem 4rem 10rem;
    text-align: center;
  }
</style>
