var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.remoteLost
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("mediaMessageOverlay.connection-lost")) +
                "\n  "
            )
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("mediaMessageOverlay.reconnecting")) +
                _vm._s(_vm.waitingDots)
            )
          ])
        ],
        1
      )
    : _vm.cameraMuted && _vm.microphoneMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("mediaMessageOverlay.local-device.both.muted")) +
                "\n  "
            )
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("mediaMessageOverlay.local-device.both.info")))
          ])
        ],
        1
      )
    : _vm.cameraMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("mediaMessageOverlay.local-device.video.muted")) +
                "\n  "
            )
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("mediaMessageOverlay.local-device.video.info"))
            )
          ])
        ],
        1
      )
    : _vm.microphoneMuted
    ? _c(
        "div",
        { staticClass: "message-overlay" },
        [
          _c("SectionTitle", { attrs: { light: "", center: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("mediaMessageOverlay.local-device.audio.muted")) +
                "\n  "
            )
          ]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("mediaMessageOverlay.local-device.audio.info"))
            )
          ])
        ],
        1
      )
    : _vm.remoteJoined
    ? _c("div", [
        _vm.patientCameraMuted && _vm.patientMicrophoneMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("mediaMessageOverlay.remote-device.both.muted")
                      ) +
                      "\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("mediaMessageOverlay.remote-device.both.info")
                    )
                  )
                ])
              ],
              1
            )
          : _vm.patientCameraMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("mediaMessageOverlay.remote-device.video.muted")
                      ) +
                      "\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("mediaMessageOverlay.remote-device.video.info")
                    )
                  )
                ])
              ],
              1
            )
          : _vm.patientMicrophoneMuted
          ? _c(
              "div",
              { staticClass: "message-overlay" },
              [
                _c("SectionTitle", { attrs: { light: "", center: "" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("mediaMessageOverlay.remote-device.audio.muted")
                      ) +
                      "\n    "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("mediaMessageOverlay.remote-device.audio.info")
                    )
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }