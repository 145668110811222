var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-interface" },
    [
      _c("SectionTitle", { attrs: { center: "" } }, [
        _c("span", [
          _vm._v(" " + _vm._s(_vm.$t("paymentInterface.title")) + " ")
        ])
      ]),
      _c("CreditCardsPictograms"),
      !_vm.paymentAccepted
        ? _c("div", { staticClass: "error" }, [
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("paymentInterface.error.1")) + " ")
            ]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("paymentInterface.error.2")) + " ")
            ])
          ])
        : _c(
            "div",
            { staticClass: "container" },
            [
              _c("p", { staticClass: "description" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("paymentInterface.description")) +
                    "\n    "
                )
              ]),
              _c("CreditCardAddForm", {
                attrs: { required: "" },
                on: {
                  close: function($event) {
                    return _vm.$router.back()
                  },
                  create: _vm.reloadPaymentMethods
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }