<template>
  <div class="post-calling-interface">
    <!-- BAD RATING -->
    <template v-if="rating === 0">
      <FontAwesomeIcon :icon="$icons.thumbsDown" class="icon" size="4x" />
      <SectionTitle center light>
        {{ $t('postCallingInterface.bad-rating.title') }}
      </SectionTitle>
      <p class="text" v-html="$t('postCallingInterface.bad-rating.text')"/>
      <div class="actions single">
        <ButtonGreen class="action" :link-to="{ name: 'pharmacy' }">
          {{ $t('postCallingInterface.bad-rating.button') }}
        </ButtonGreen>
      </div>
    </template>

    <!-- GOOD RATING -->
    <template v-else-if="rating === 1">
      <FontAwesomeIcon :icon="$icons.thumbsUp" class="icon" size="4x" />
      <SectionTitle center light>
        {{ $t('postCallingInterface.good-rating.title') }}
      </SectionTitle>
      <p class="text">
        {{ $t('postCallingInterface.good-rating.text') }}
      </p>
      <div class="actions single">
        <ButtonGreen class="action" :link-to="{ name: 'pharmacy' }">
          {{ $t('postCallingInterface.good-rating.button') }}
        </ButtonGreen>
      </div>
    </template>

    <!-- NO RATING -->
    <template v-else>
      <FontAwesomeIcon :icon="$icons.thumbsUp" class="icon" size="4x" />
      <SectionTitle center light>
        {{ $t('postCallingInterface.no-rating.title') }}
      </SectionTitle>
      <p class="text">
        {{ $t('postCallingInterface.no-rating.text') }}
      </p>
      <div class="actions">
        <ButtonWhite
          :busy="isRating"
          :icon="$icons.thumbsDown"
          @click="rate(0)"
          class="action"
          outlined
        >
          {{ $t('postCallingInterface.no-rating.button-bad') }}
        </ButtonWhite>
        <ButtonGreen
          :busy="isRating"
          :icon="$icons.thumbsUp"
          @click="rate(1)"
          class="action"
        >
          {{ $t('postCallingInterface.no-rating.button-good') }}
        </ButtonGreen>
      </div>
    </template>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import ButtonGreen from '../ButtonGreen';
  import ButtonWhite from '../ButtonWhite';

  export default {
    name: 'PostCallingInterface',
    components: { SectionTitle, ButtonGreen, ButtonWhite },
    props: {
      appointmentId: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        rating: null,
      };
    },
    computed: {
      isRating() {
        return this.$store.state.encounters.isRating;
      },
    },
    methods: {
      async rate(rating) {
        try {
          await this.$store.dispatch('encountersRate', {
            id: this.$store.state.encounters.current.id,
            rate: rating,
          });
          this.rating = rating;
        } catch (e) {
          this.$addError(this.$t('postCallingInterface.error'));
          throw e;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .post-calling-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    color: #FFFFFF;
  }

  .section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .text {
    max-width: 38rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #FFFFFF;
    margin: 3rem 0 4rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 60rem;

    @include onDesktop {
      flex-direction: row;
    }
  }

  .action {
    margin: 0.5rem 1rem;
    flex: 1;

    .single & {
      flex: none;
    }
  }
</style>
