<template>
  <div class="countdown-interface">
    <SectionTitle center light>
      {{ $t('countDownInterface.title') }} <br/>
      {{ $t('countDownInterface.text') }}
    </SectionTitle>
    <div class="action-wrapper">
      <div class="user-card">
        <UserAvatar class="user-avatar" :user-name="patientFullName" :src="patientAvatarURL"/>
        <span class="full-name">{{ patientFullName }}</span>
      </div>
      <hr class="link">
      <span class="countdown">{{ timeLeft }}</span>
      <hr class="link">
      <div class="user-card">
        <UserAvatar class="user-avatar" :user-name="practitionerFullName" :src="practitionerAvatarURL"/>
        <span class="full-name">{{ practitionerFullName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatFullName } from '@/helpers/format.js';
  import SectionTitle from '../SectionTitle';
  import UserAvatar from '../UserAvatar';

  export default {
    name: 'CountdownInterface',
    components: { SectionTitle, UserAvatar },
    props: {
      countdownDuration: {
        type: Number,
        required: true,
        default: 5000,
      },
      patientFullName: {
        type: String,
        required: true,
      },
      practitioner: {
        type: Object,
        required: true,
        default: {},
      },
    },
    data() {
      return {
        timeLeft: this.countdownDuration / 1000,
      };
    },
    mounted() {
      this.startTimer();
    },
    computed: {
      patientAvatarURL() {
        return this.$store.state.patients.profile.picture;
      },
      practitionerAvatarURL() {
        return this.practitioner.picture;
      },
      practitionerFullName() {
        return formatFullName(this.practitioner);
      }
    },
    methods: {
      startTimer() {
        this.timeLeft = this.countdownDuration / 1000;

        const intervalId = this.$setInterval(() => {
          this.timeLeft -= 1;

          if (this.timeLeft <= 0) {
            clearInterval(intervalId);
            this.$emit('done');
          }
        }, 1000);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .countdown-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: calc(100vw - 20rem);
    max-width: 90rem;
    @include onMobile {
      width: 100vw;
      transform: scale(0.7);
    }
  }

  .user-card {
    position: relative;
  }

  .link {
    border: none;
    border-top: 0.3rem solid $lightTextColor;
    flex: 1;
    background: none;
  }

  .full-name {
    position: absolute;
    width: 25rem;
    font-family: $titleFont;
    color: $lightTextColor;
    font-size: 2rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 3rem;
  }

  .countdown {
    color: $lightTextColor;
    font-size: 6.4rem;
    border: 2px solid $lightTextColor;
    display: inline-flex;
    width: 10rem;
    height: 10rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    font-weight: 100;
  }

  ::v-deep .section-title {
    padding-right: 0;
  }
</style>
