var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calling-interface",
      class: {
        "has-media-selector": _vm.hasMediaSelector,
        "has-panel": !!_vm.visiblePanel
      }
    },
    [
      _c(
        "div",
        { staticClass: "video-container" },
        [
          _c("div", { staticClass: "cameras" }, [
            _c("div", { staticClass: "consultation-camera-remote" }, [
              _vm.remoteVideoStream
                ? _c("video", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$media.panel || !_vm.isPanelVisible,
                        expression: "!$media.panel || !isPanelVisible"
                      }
                    ],
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.remoteVideoStream }
                  })
                : _vm._e(),
              _vm.remoteAudioStream
                ? _c("audio", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.remoteAudioStream }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "consultation-camera-local" }, [
              _vm.localVideoStream
                ? _c("video", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.localVideoStream }
                  })
                : _vm._e(),
              _vm.localAudioStream
                ? _c("audio", {
                    attrs: { autoplay: "" },
                    domProps: { srcObject: _vm.localAudioStream }
                  })
                : _vm._e()
            ])
          ]),
          !_vm.hasPractitionerJoined
            ? _c("div", { staticClass: "waiting-for-practitioner" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("callingInterface.waiting-practitioner.1"))
                ),
                _c("br"),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("callingInterface.waiting-practitioner.2")) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _c("MediaMessageOverlay"),
          _c("ControlBar", {
            on: {
              toggleFilesPanel: _vm.toggleFilesPanel,
              toggleTelemetryPanel: _vm.toggleTelemetryPanel
            }
          })
        ],
        1
      ),
      _c(
        "Panel",
        {
          attrs: {
            transparent: "",
            opened: _vm.isPanelVisible,
            title: _vm.panelTitle,
            "max-width": "90rem"
          },
          on: {
            close: function($event) {
              _vm.visiblePanel = null
            }
          },
          scopedSlots: _vm._u([
            {
              key: "panel",
              fn: function() {
                return [
                  _vm.isFilePanelOpen
                    ? _c("FilesPanel", {
                        attrs: {
                          "appointment-id": _vm.appointmentId,
                          "practitioner-id": _vm.practitionerId
                        }
                      })
                    : _vm._e(),
                  _vm.isTelemetryPanelOpen
                    ? _c("TelemetryPanel", {
                        attrs: {
                          encounter: _vm.encounter,
                          "appointment-id": _vm.appointmentId,
                          "practitioner-id": _vm.practitionerId
                        },
                        on: {
                          terminate: function($event) {
                            _vm.visiblePanel = null
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$media.panel,
                    expression: "$media.panel"
                  }
                ],
                staticClass: "consultation-camera-local",
                class: [
                  _vm.$media.panel && _vm.isPanelVisible
                    ? "consultation-camera-local__panel"
                    : ""
                ]
              },
              [
                _vm.localVideoStream
                  ? _c("video", {
                      attrs: { autoplay: "" },
                      domProps: { srcObject: _vm.remoteVideoStream }
                    })
                  : _vm._e()
              ]
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }