var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "telemetry-panel" }, [
    !_vm.doc2uStarted
      ? _c(
          "div",
          [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("telemetry.info")) }
            }),
            _c(
              "ButtonGreen",
              {
                attrs: { small: "", rounded: "" },
                on: { click: _vm.toggleDoc2u }
              },
              [_vm._v(_vm._s(_vm.$t("telemetry.start")))]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.doc2uStarted
      ? _c(
          "div",
          { staticClass: "container-iframe" },
          [
            _c("div", { staticClass: "container-iframe-top" }, [
              this.$store.state.observations.isFetching
                ? _c(
                    "div",
                    { staticClass: "container-spinner" },
                    [_c("Spinner")],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "container-iframe__datablock" },
                    [
                      _c(
                        "ButtonGreen",
                        { on: { click: _vm.createObservations } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("telemetry.constantes-page.save-data")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm.searchLength > 0
                        ? _c(
                            "div",
                            [
                              _c("LastObservations", {
                                attrs: {
                                  "search-length": _vm.searchLength,
                                  observations: this.$store.state.observations
                                    .search
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("telemetry.constantes-page.no-data")
                                )
                              )
                            ])
                          ])
                    ],
                    1
                  )
            ]),
            _c("iframe", {
              staticClass: "doc2u-content",
              attrs: {
                src:
                  "https://app.doc2u.fr/room?t=" +
                  _vm.encounter.doc2uData.token +
                  "&practitionerMode=local&postMessage=true"
              }
            }),
            _c(
              "ButtonRed",
              {
                staticClass: "doc2u-button-finish",
                attrs: { rounded: "", small: "" },
                on: { click: _vm.terminate }
              },
              [_vm._v(_vm._s(_vm.$t("telemetry.terminate")))]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }