var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "files-panel" },
    [
      _c("p", [_vm._v(_vm._s(_vm.$t("filesPanel.content.0")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("filesPanel.content.1")))]),
      _c("SharedFiles", {
        ref: "sharedFiles",
        attrs: {
          "appointment-id": _vm.appointmentId,
          "practitioner-id": _vm.practitionerId
        },
        on: { upload: _vm.handleUploadedFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }