var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "waiting-room-interface" },
    [
      _c("FontAwesomeIcon", {
        staticClass: "icon",
        attrs: { icon: _vm.$icons.clock },
        on: { click: _vm.stopDevices }
      }),
      _c("SectionTitle", { attrs: { center: "" } }, [
        _vm._v(_vm._s(_vm.$t("waitingRoomInterface.title")))
      ]),
      _c("span", { staticClass: "subtitle" }, [
        _vm._v(_vm._s(_vm.$t("waitingRoomInterface.subtitle")))
      ]),
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.$t("waitingRoomInterface.not-available", {
              name: _vm.practitionerFullName
            })
          )
        )
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("waitingRoomInterface.waiting-advice")))]),
      _c(
        "div",
        { staticClass: "tips-container" },
        [
          _c(
            "div",
            { staticClass: "control", on: { click: _vm.previousTip } },
            [
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.chevronLeft } })
            ],
            1
          ),
          _c("div", { staticClass: "tip" }, [
            _c("h4", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "waitingRoomInterface.tips." +
                      _vm.currentTipIndex +
                      ".title"
                  )
                )
              )
            ]),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "waitingRoomInterface.tips." + _vm.currentTipIndex + ".text"
                  )
                )
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "control", on: { click: _vm.nextTip } },
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.$icons.chevronRight }
              })
            ],
            1
          ),
          _c("FontAwesomeIcon", {
            staticClass: "heart-icon",
            attrs: { icon: _vm.$icons.heart }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "telemetry", on: { click: _vm.toggleTelemetryPanel } },
        [
          _c("FontAwesomeIcon", {
            staticClass: "helloscope-icon",
            attrs: { icon: _vm.$icons.doc2u }
          })
        ],
        1
      ),
      _c(
        "AppPanel",
        {
          attrs: {
            transparent: "",
            opened: _vm.isPanelVisible,
            title: _vm.panelTitle,
            "max-width": "90rem"
          },
          on: {
            close: function($event) {
              _vm.visiblePanel = null
            }
          }
        },
        [
          _vm.isFilePanelOpen
            ? _c("FilesPanel", {
                attrs: {
                  "appointment-id": _vm.appointmentId,
                  "practitioner-id": _vm.practitionerId
                }
              })
            : _vm._e(),
          _vm.isTelemetryPanelOpen
            ? _c("TelemetryPanel", {
                attrs: {
                  encounter: _vm.encounter,
                  "appointment-id": _vm.appointmentId,
                  "practitioner-id": _vm.practitionerId
                },
                on: {
                  terminate: function($event) {
                    _vm.visiblePanel = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }