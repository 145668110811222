var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control-bar" }, [
    _c(
      "div",
      {
        staticClass: "control",
        class: { muted: _vm.microphoneMuted },
        on: { click: _vm.toggleMicrophone }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.microphoneIcon } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "control",
        class: { muted: _vm.cameraMuted },
        on: { click: _vm.toggleCamera }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.cameraIcon } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "control",
        on: {
          click: function($event) {
            return _vm.$emit("toggleFilesPanel")
          }
        }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.filesPanel } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "control control--telemetry",
        on: {
          click: function($event) {
            return _vm.$emit("toggleTelemetryPanel")
          }
        }
      },
      [_c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.doc2u } })],
      1
    ),
    _c(
      "div",
      { staticClass: "control" },
      [_c("ChatIcon", { attrs: { small: "" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }