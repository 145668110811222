<template>
  <AppLayout :footer="false" :header="false" :nearest="false">
    <main v-if="isLoaded" class="consultation-page fullscreen">
      <MediaSelector v-if="isStepWaitingRoom || isStepCalling" />
      <SummaryInterface
        v-if="isStepSummary"
        :encounter="encounter"
        :payment-accepted="practitioner.acceptPayment"
        @start="showSummary = false"
      />
      <ErrorInterface v-else-if="isStepError" :error-code="errorCode">
        {{ errorMessage }}
      </ErrorInterface>
      <ErrorInterface v-else-if="!isNavigatorOnline" :title="$t('consultationPage.no-connexion')" error-code="NAVIGATOR_OFFLINE">
        {{ $t('consultationPage.waiting-connexion') }}
      </ErrorInterface>
      <PostCallingInterface
        v-else-if="isStepPostCalling"
        :appointment-id="appointmentId"
      />
      <AppLoader v-else-if="isStepTerminating">
        {{ $t('consultationPage.stop-consultation') }}
      </AppLoader>
      <CountdownInterface
        v-else-if="isStepCountdown"
        :countdown-duration="4000"
        :patient-full-name="patientFullName"
        :practitioner="practitioner"
        @done="join"
      />
      <AppLoader v-else-if="isStepJoining">
        {{ $t('consultationPage.start-consultation') }}
      </AppLoader>
      <WaitingRoomInterface
        v-else-if="isStepWaitingRoom"
        :patient-full-name="patientFullName"
        :practitioner="practitioner"
        :appointment-id="appointmentId"
        :practitioner-id="practitionerId"
        :encounter="encounter"
      />
      <CallingInterface
        v-else-if="isStepCalling"
        :appointment-id="appointmentId"
        :practitioner-id="practitionerId"
        :encounter="encounter"
      />
      <AppLoader v-else>
        {{ $t('consultationPage.loader') }}
      </AppLoader>
    </main>

    <template v-if="isChatAvailable">
      <ChatIcon v-if="!isStepCalling" :dark="isStepWaitingRoom" />

      <AppPanel class="app-panel" :opened="isChatOpened" title="Chat" @close="closeChat">
        <Chat :appointment-id="appointmentId" :remote-username="practitionerFullName" />
        <div v-show="$media.panel && isChatOpened" class="consultation-camera-local draggable">
            <video v-if="localVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
        </div>
      </AppPanel>
    </template>

    <AppConfirmation
      ref="leaveConsultation"
      :title="$t('consultationPage.stop')"
      @confirm="(next) => next()"
    />
  </AppLayout>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import PaymentInterface from '@/components/Consultation/PaymentInterface';
  import WaitingRoomInterface from '@/components/Consultation/WaitingRoomInterface';
  import CountdownInterface from '@/components/Consultation/CountdownInterface';
  import CallingInterface from '@/components/Consultation/CallingInterface';
  import AppLayout from '@/components/AppLayout';
  import PostCallingInterface from '@/components/Consultation/PostCallingInterface';
  import MediaSelector from '@/components/Consultation/MediaSelector';
  import { STEP } from '@/config/store_modules/sdk';
  import ErrorInterface from '@/components/Consultation/ErrorInterface';
  import AppLoader from '@/components/AppLoader';
  import * as Sentry from '@sentry/browser';
  import { getSetting } from '@/helpers/tools.js';
  import Chat from '@/components/Chat/Chat';
  import { formatCivility, formatFullName } from '@/helpers/format';
  import ChatIcon from '@/components/Chat/ChatIcon';
  import SummaryInterface from '@/components/Consultation/SummaryInterface';
  import AppPanel from "@/components/AppPanel.vue";

  const { STATUS, REMOTE_STATUS, browserSupported } = window.PlatformSDK;

  export default {
    name: 'Consultation',
    components: {
      AppPanel,
      AppConfirmation,
      SummaryInterface,
      ChatIcon,
      Chat,
      AppLoader,
      ErrorInterface,
      MediaSelector,
      PostCallingInterface,
      AppLayout,
      CallingInterface,
      CountdownInterface,
      WaitingRoomInterface,
      PaymentInterface,
    },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showSummary: true,
        isNavigatorOnline: navigator.onLine,
      }
    },
    async beforeMount() {
      if (!browserSupported) {
        if (this.$isDev) {
          this.$addError(this.$t('consultationPage.error-browser'));
        } else {
          return this.$router.push({ name: 'incompatible-browser' });
        }
      }
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      window.addEventListener('unload', this.handleUnload);
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);
      await this.$store.dispatch('appointmentsClearCurrent');
      await this.$store.dispatch('encountersClearCurrent');
    },
    async mounted() {
      if (!browserSupported) {
        return;
      }

      await this.$store.dispatch('appLoaderShow');

      await this.$store.dispatch('paymentMethodsFetchAll');
      await this.loadAppointment();
      const practitionerPromise = this.loadPractitioner();

      if (this.appointment.encounters?.[0]) {
        await this.loadEncounter(this.appointment.encounters?.[0].id);
      } else {
        await this.createEncounter();
      }

      this.showSummary = !this.encounter.authorized;

      await practitionerPromise;

      if (getSetting('SENTRY_ENABLED')) {
        Sentry.configureScope(scope => scope.setTags({
          appointment_id: this.appointment.id,
          patient_id: this.appointment.patient.id,
          product_id: this.appointment.product.id,
          encounter_id: this.encounter.id,
        }));
      }

      await this.$store.dispatch('appLoaderHide');
    },
    async beforeDestroy() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      window.removeEventListener('unload', this.handleUnload);
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);

      await this.$store.dispatch('sdkDestroy');
      await this.$store.dispatch('chatLeave');
    },
    beforeRouteLeave(to, from, next) {
      if (!this.isStepCalling || to.meta.ignore) {
        return next();
      }

      this.$confirm(this.$refs.leaveConsultation, next);
    },
    watch: {
      canConnect(canConnect) {
        if (!canConnect) { return; }

        this.connect();
      },
      isChatAvailable: {
        immediate: true,
        async handler(isChatAvailable) {
          await this.$store.dispatch(isChatAvailable ? 'chatJoin' : 'chatLeave', { appointmentId: this.appointmentId });
        },
      },
      isStepPostCalling(isStepPostCalling) {
        if (isStepPostCalling) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : this.$t("consultationPage.teleconsultation-done")
          });
        }
      },
    },
    computed: {
      canConnect() { // Has watcher
        return this.isNavigatorOnline && this.isLoaded && !this.isStepSummary;
      },
      canJoin() { // Has watcher
        return this.$store.getters.sdkCanJoin;
      },
      isLoaded() { // Has watcher
        return !!this.encounter && !!this.appointment && !!this.practitioner;
      },
      practitionerId() { return this.appointment.practitioner.id; },
      patientFullName() { return this.$store.getters.currentAppointmentPatientFullName; },
      practitionerFullName() {
        if (!this.practitioner) {
          return '';
        }

        return `${formatCivility(this.practitioner.civility)} ${formatFullName(this.practitioner)}`;
      },
      appointment() { return this.$store.state.appointments.current; },
      encounter() { return this.$store.state.encounters.current; },
      practitioner() { return this.$store.state.practitioners.current; },
      hasPaymentMethod() { return this.$store.state.paymentMethods.all.length > 0; },
      status() {
        return this.$store.state.sdk.local.status;
      },
      practitionerStatus() {
        return this.$store.state.sdk.remote.status;
      },
      step() {
        return this.$store.state.sdk.step;
      },
      isStepSummary() {
        return this.showSummary;
      },
      isStepError() {
        return this.step === STEP.ERROR;
      },
      isStepCountdown() {
        return this.step === STEP.COUNTDOWN;
      },
      isStepJoining() {
        return this.status === STATUS.JOINING;
      },
      isStepWaitingRoom() {
        return this.step === STEP.WAITING_ROOM;
      },
      isStepCalling() {
        return this.step === STEP.CALLING;
      },
      isStepTerminating() {
        return this.status === STATUS.TERMINATING;
      },
      isStepPostCalling() {
        return this.step === STEP.POST_CALLING || this.status === STATUS.TERMINATED;
      },
      hasPractitionerJoined() {
        return this.practitionerStatus === REMOTE_STATUS.JOINED;
      },
      isChatAvailable() {
        return this.appointment && (this.isStepError || this.isStepCountdown || this.isStepJoining || this.isStepWaitingRoom || this.isStepCalling);
      },
      isChatOpened() {
        return this.$store.state.chat.isOpen;
      },
      errorCode() {
        return this.$store.state.sdk.errorCode;
      },
      errorMessage() {
        return this.$store.state.sdk.errorMessage || this.$t('consultationPage.message-error');
      },
      localVideoStream() {
          return this.$store.state.sdk.local.video.stream;
      },
      remoteVideoStream() {
            return this.$store.state.sdk.remote.video.stream;
        },
    },
    methods: {
      async loadAppointment() {
        await this.$store.dispatch('appointmentsFetchOne', { id: this.appointmentId });

        if (['TERMINATED', 'CLOSED', 'PAYMENT_REQUIRED'].includes(this.appointment.status)) {
          this.$addError(this.$t('consultationPage.already-appointment'), true);
          return this.$router.push({ name: 'home' });
        }
      },
      async loadEncounter(id) {
        try {
          await this.$store.dispatch('appLoaderShow');
          await this.$store.dispatch('encountersClearCurrent');
          await this.$store.dispatch('encountersFetchOne', { id });
        } catch (e) {
          this.$addError(this.$t('consultationPage.error-load'));
          throw e;
        } finally {
          await this.$store.dispatch('appLoaderHide');
        }
      },
      async createEncounter() {
        await this.$store.dispatch('encountersCreate', {
          appointmentId: this.appointmentId,
          practitionerId: this.appointment.practitioner.id,
          productId: this.appointment.product.id,
        });
      },
      async loadPractitioner() {
        this.$store.dispatch('practitionersFetchOne', { id: this.appointment.practitioner.id });
      },
      async initSDK() {
        await this.$store.dispatch('sdkCreateInstance', {
          encounterId: this.encounter.id,
          apiKey: getSetting('PLATFORM_API_PUBLIC_KEY'),
          apiToken: this.$store.getters.platformJwtForCurrentPatient,
        });
      },
      async connect() {
        await this.initSDK();
        await this.$store.dispatch('sdkConnect');
        this.$setTimeout(() => this.$store.dispatch('sdkEnableNotificationSound'), 3000);
      },
      async join() {
        await this.$store.dispatch('sdkJoin');
      },
      toggleCamera() {
        this.$store.dispatch('sdkToggleVideoDevice');
      },
      toggleMicrophone() {
        this.$store.dispatch('sdkToggleAudioDevice');
      },
      handleBeforeUnload(event) {
        if (this.status !== STATUS.JOINED) {
          return;
        }

        event.preventDefault();
        event.returnValue = '';
      },
      handleUnload() {
        this.$store.dispatch('sdkDestroy');
      },
      closeChat() {
        this.$store.dispatch('chatClose');
      },
      handleOnline() {
        this.isNavigatorOnline = true;
      },
      handleOffline() {
        this.isNavigatorOnline = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-container .consultation-page {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: $gradientBackground;
  }

  .chat-icon {
    position: absolute;
    top: 10rem;
    right: 2rem;
  }

  .app-panel ::v-deep .panel {
    @media (max-width: 1279px) {
      width: 100vw;
      max-width: none;
      &>.content {
        height: 100%;
      }
    }
  }
  .draggable {
    width: 12rem;
    height: 14rem;
    border-radius: 10px;
    float: left;
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    z-index: 2;
  }
  .draggable.is-pointer-down {
    z-index: 2;
  }
  .draggable.is-dragging { opacity: 0.7; }

  .consultation-camera-local {
    right: 1.5rem;
    width: 12rem;
    height: 14rem;
    overflow: hidden;
    border-radius: 0.8rem;
    transform: scale(-1, 1);

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
