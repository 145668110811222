<!--
Emitted events:
- close (only if backPath is NOT provided)
-->
<template>
  <transition @after-leave="canBeClosed = true">
    <div v-if="opened" class="app-panel">
      <router-link v-if="backPath" :class="{ transparent }" :to="backPath" class="backdrop" />
      <div v-else :class="{ transparent }" class="backdrop" @click="close" />
      <div :style="{ '--maxWidth': maxWidth }" class="panel">
        <div class="content">
            <div v-show="$media.panel || this.opened" class="container-drag" >
                <div v-show="$media.panel && this.opened" class="consultation-camera-local draggable">
                    <video v-if="localVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
                </div>
              <SectionTitle class="panel-title">
                  {{ title }}
                  <router-link v-if="backPath" :to="backPath" class="close-panel">
                      <FontAwesomeIcon :icon="$icons.closePanel" class="close-panel-icon" />
                  </router-link>
                  <FontAwesomeIcon v-else :icon="$icons.closePanel" class="close-panel close-panel-icon" @click="close" />
              </SectionTitle>
              <slot name="panel"/>
            </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import SectionTitle from '../../SectionTitle';
export default {
  name: 'AppPanel',
  components: { SectionTitle },
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
    },
    maxWidth: {
      type: String,
      required: false,
      default: '60rem',
    },
    backPath: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      canBeClosed: true,
        draggies:[]
    };
  },
  methods: {
    close() {
      if (!this.canBeClosed) {
        return;
      }

      this.canBeClosed = false;

      this.$emit('close');
    },
  },
    computed: {
        localVideoStream() {
            return this.$store.state.sdk.local.video.stream;
        },
        remoteVideoStream() {
            return this.$store.state.sdk.remote.video.stream;
        },
    },
};
</script>

<style lang="scss" scoped>
.app-panel {
  font-size: 1.4rem;
  font-weight: 200;
  position: fixed;
  z-index: 2010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 3;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s;
  opacity: 0.9;
  /*
  background: $gradientBackground;
  */

  &.transparent {
    opacity: 0.05;
  }

  .v-enter &,
  .v-leave-to & {
    opacity: 0;
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  overflow: auto;
  width: 100%;
  max-width: var(--maxWidth);
  height: 100%;
  background-color: #FFFFFF;
  @media (max-width: 1279px) {
    width: 100vw;
    max-width: none;
  }
}

.close-panel {
  position: absolute;
  right: 0;
}

.close-panel-icon {
  font-size: 3rem;
  cursor: pointer;
  color: #F46868;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  /*
  height: calc(100vh - 70px);
  */
  @include onMobile {
    padding: 1.5rem 1.5rem 10rem 1.5rem;
  }
}
.container-drag {
  @include onMobile {
    padding: 2rem 0rem 2rem 1rem;
  }
  width: 100%;
  height: calc(100vh - 80px);
  padding: 2rem;
}
.draggable {
  @include onDesktop {
    left: 1rem;
    bottom: 1rem;
  }
  width: 12rem;
  height: 14rem;
  border-radius: 10px;
  float: left;
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  z-index: 2;
}
.draggable.is-pointer-down {
  z-index: 2;
}
.draggable.is-dragging { opacity: 0.7; }

.consultation-camera-local {
  right: 1.5rem;
  width: 12rem;
  height: 14rem;
  overflow: hidden;
  border-radius: 0.8rem;
  transform: scale(-1, 1);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
