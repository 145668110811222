<template>
  <div class="media-selector" :class="{ collapsed }" v-if="enabled">
    <div v-if="audioDevices.length > 1" class="media-selector-item">
      <label for="video-device"> {{ $t('mediaSelector.choose-micro') }} </label>
      <SelectWrapper>
        <select id="audio-device" v-model="audioDeviceId">
          <option v-for="(audioDevice, index) in audioDevices" :value="audioDevice.deviceId">
            {{ audioDevice.label || `Micro #${index + 1}`}}
          </option>
        </select>
      </SelectWrapper>
    </div>
    <div v-if="videoDevices.length > 1" class="media-selector-item">
      <label for="video-device"> {{ $t('mediaSelector.choose-camera') }} </label>
      <SelectWrapper>
        <select id="video-device" v-model="videoDeviceId">
          <option v-for="(videoDevice, index) in videoDevices" :value="videoDevice.deviceId">
            {{ videoDevice.label || `Camera #${index + 1}`}}
          </option>
        </select>
      </SelectWrapper>
    </div>
    <div class="toggle-icon" @click="collapsed = !collapsed">
      <FontAwesomeIcon :icon="$icons.encounterCameraToggle" />
    </div>
  </div>
</template>

<script>
  import SelectWrapper from '../Form/SelectWrapper';

  export default {
    name: 'MediaSelector',
    components: { SelectWrapper },
    data() {
      return {
        collapsed: true,
      };
    },
    computed: {
      audioDeviceId: {
        get() {
          return this.$store.state.sdk.local.audio.deviceId;
        },
        set(audioDeviceId) {
          this.$store.dispatch('sdkChangeAudioDevice', { deviceId: audioDeviceId });
        },
      },
      videoDeviceId: {
        get() {
          return this.$store.state.sdk.local.video.deviceId;
        },
        set(videoDeviceId) {
          this.$store.dispatch('sdkChangeVideoDevice', { deviceId: videoDeviceId });
        },
      },
      audioDevices() {
        return this.$store.state.sdk.local.audio.devices;
      },
      videoDevices() {
        return this.$store.state.sdk.local.video.devices;
      },
      enabled() {
        return this.$store.getters.sdkShowDeviceSelector;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .media-selector {
    background-color: #FFFFFF;
    z-index: 10;
    position: absolute;
    right: 0;
    width: 100%;
    max-height: 16rem;
    overflow: hidden;
    white-space: nowrap;

    @include onMobile {
      transition: width 300ms, max-height 300ms 300ms, background-color 300ms;

      &.collapsed {
        transition: max-height 300ms, width 300ms 300ms, background-color 300ms;
        left: auto;
        width: 5rem;
        max-height: 4rem;
        border-radius: 0 0 0 1rem;
        background-color: #00000015;

        &:hover {
          background-color: #FFFFFF;
        }
      }
    }

    @include onDesktop {
      background-color: #00000015;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #00000010;
    }
  }

  label, select {
    padding: 1.6rem !important;
    font-size: 1.5rem;
  }

  select {
    border: none;
    background: transparent;
    color: lighten($mainColor, 15%);
  }

  .media-selector-item {
    padding-top: 1rem;
    opacity: 1;
    transition: opacity 600ms;

    .collapsed & {
      opacity: 0;

      @include onDesktop {
        opacity: 1;
      }
    }

    @include onDesktop {
      display: flex;
      padding-top: 0;
    }
  }

  .toggle-icon {
    position: absolute;
    font-size: 3rem;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0.2rem 1rem 0.2rem 1rem;
    color: #00000040;

    @include onDesktop {
      display: none;
    }
  }
</style>
