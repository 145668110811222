<template>
  <div class="calling-interface" :class="{ 'has-media-selector': hasMediaSelector, 'has-panel': !!visiblePanel }">
    <div class="video-container">
      <div class="cameras">
        <div class="consultation-camera-remote">
          <video v-show="!$media.panel || !isPanelVisible" v-if="remoteVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
          <audio v-if="remoteAudioStream" autoplay :srcObject.prop="remoteAudioStream" />
        </div>
        <div class="consultation-camera-local">
          <video v-if="localVideoStream" autoplay :srcObject.prop="localVideoStream" />
          <audio v-if="localAudioStream" autoplay :srcObject.prop="localAudioStream" />
        </div>
      </div>
      <div class="waiting-for-practitioner" v-if="!hasPractitionerJoined">
        {{ $t('callingInterface.waiting-practitioner.1') }}<br/>
        {{ $t('callingInterface.waiting-practitioner.2') }}
      </div>

      <MediaMessageOverlay />

      <ControlBar @toggleFilesPanel="toggleFilesPanel" @toggleTelemetryPanel="toggleTelemetryPanel" />
    </div>
    <Panel
      transparent
      :opened="isPanelVisible"
      :title="panelTitle"
      max-width="90rem"
      @close="visiblePanel = null"
    >
      <template>
          <div v-show="$media.panel" class="consultation-camera-local" :class="[$media.panel && isPanelVisible ? 'consultation-camera-local__panel' : '']">
          <video v-if="localVideoStream" autoplay :srcObject.prop="remoteVideoStream" />
          </div>
      </template>
      <template v-slot:panel>
          <FilesPanel v-if="isFilePanelOpen" :appointment-id="appointmentId" :practitioner-id="practitionerId" />
          <TelemetryPanel v-if="isTelemetryPanelOpen" @terminate="visiblePanel = null" :encounter="encounter"
                          :appointment-id="appointmentId" :practitioner-id="practitionerId"/>
      </template>
    </Panel>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import UserAvatar from '../UserAvatar';
  import AutoHeightTextarea from '../Form/AutoHeightTextarea';
  import MediaMessageOverlay from './CallingInterface/MediaMessageOverlay';
  import ControlBar from './CallingInterface/ControlBar';
  import Panel from './CallingInterface/Panel';
  import FilesPanel from './CallingInterface/FilesPanel';
  import TelemetryPanel from './CallingInterface/TelemetryPanel';
  import Chat from '@/components/Chat/Chat';
  import {formatCivility, formatFullName} from "@/helpers/format";


  const { REMOTE_STATUS } = window.PlatformSDK;

  const PANEL = {
    FILES: 'FILES',
    TELEMETRY: 'TELEMETRY',
  };

  export default {
    name: 'CallingInterface',
    components: { FilesPanel, Chat, TelemetryPanel, Panel, ControlBar, MediaMessageOverlay, AutoHeightTextarea, UserAvatar, SectionTitle },
    props: {
      encounter: {
        type: Object,
        required: true,
      },
      appointmentId: {
        type: String,
        required: true,
      },
      practitionerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        visiblePanel: null,
      };
    },
    computed: {
      hasPractitionerJoined() {
        return this.$store.state.sdk.remote.status === REMOTE_STATUS.JOINED;
      },
      localAudioStream() {
        return this.$store.state.sdk.local.audio.stream;
      },
      localVideoStream() {
        return this.$store.state.sdk.local.video.stream;
      },
      remoteAudioStream() {
        return this.$store.state.sdk.remote.audio.stream;
      },
      remoteVideoStream() {
        return this.$store.state.sdk.remote.video.stream;
      },
      isPanelVisible() {
        return this.visiblePanel !== null;
      },
      isFilePanelOpen() {
        return this.visiblePanel === PANEL.FILES
      },
      isTelemetryPanelOpen() {
        return this.visiblePanel === PANEL.TELEMETRY
      },
      hasMediaSelector() {
        return this.$store.getters.sdkShowDeviceSelector;
      },
      panelTitle() {
        switch (this.visiblePanel) {
          case PANEL.FILES:
            return 'Documents partagés';
          case PANEL.TELEMETRY:
            return this.$t('telemetry.title');
          default:
            return '';
        }
      }
    },
    methods: {
      toggleFilesPanel() {
        this.visiblePanel = this.visiblePanel === PANEL.FILES ? null : PANEL.FILES;
      },
      toggleTelemetryPanel() {
        this.visiblePanel = this.visiblePanel === PANEL.TELEMETRY ? null : PANEL.TELEMETRY;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .calling-interface {
    display: flex;
    width: 100%;
    height: calc(100vh - 7rem);

    @include onDesktop {
      height: 100vh;

      &.has-media-selector {
        margin-top: 5.1rem;
        height: calc(100vh - 5.1rem);
      }
    }
  }

  .video-container {
    position: relative;
    flex: 5;
    display: flex;
  }

  .cameras {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #1F1F1F;
  }

  .consultation-camera-remote {
    height: 100%;
    width: 100%;

    video {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  .consultation-camera-local {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    width: 12rem;
    height: 14rem;
    overflow: hidden;
    border-radius: 0.8rem;
    border: 1px solid $thumbnailVideoBorderColor;
    transform: scale(-1, 1);

    @include onDesktop {
      width: 18rem;
      height: 21.5rem;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .waiting-for-practitioner {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: $lightTextColor;
    text-align: center;
    font-size: 2rem;
    line-height: 10rem;
  }
  ::v-deep .app-panel {
    position: relative;
    flex: 5;
    @media (min-width: 1280px) {
      flex: 3;
    }
  }

  ::v-deep .panel-title {
    @include onMobile {
      margin-bottom: 0;

    }
  }
</style>
