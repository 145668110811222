var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "countdown-interface" },
    [
      _c("SectionTitle", { attrs: { center: "", light: "" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("countDownInterface.title")) + " "),
        _c("br"),
        _vm._v("\n    " + _vm._s(_vm.$t("countDownInterface.text")) + "\n  ")
      ]),
      _c("div", { staticClass: "action-wrapper" }, [
        _c(
          "div",
          { staticClass: "user-card" },
          [
            _c("UserAvatar", {
              staticClass: "user-avatar",
              attrs: {
                "user-name": _vm.patientFullName,
                src: _vm.patientAvatarURL
              }
            }),
            _c("span", { staticClass: "full-name" }, [
              _vm._v(_vm._s(_vm.patientFullName))
            ])
          ],
          1
        ),
        _c("hr", { staticClass: "link" }),
        _c("span", { staticClass: "countdown" }, [
          _vm._v(_vm._s(_vm.timeLeft))
        ]),
        _c("hr", { staticClass: "link" }),
        _c(
          "div",
          { staticClass: "user-card" },
          [
            _c("UserAvatar", {
              staticClass: "user-avatar",
              attrs: {
                "user-name": _vm.practitionerFullName,
                src: _vm.practitionerAvatarURL
              }
            }),
            _c("span", { staticClass: "full-name" }, [
              _vm._v(_vm._s(_vm.practitionerFullName))
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }