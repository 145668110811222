<template>
  <div class="chat">
    <div class="remote-status" :class="{ online: isRemoteOnline }">
      {{ $t(isRemoteOnline ? 'chat.connected' : 'chat.disconnected', { username: remoteUsername }) }}
    </div>

    <div class="warning">
      {{ $t('chat.warning') }}
    </div>

    <div ref="messages" class="messages">
      <div class="messages-group" :class="messagesGroup.type" v-for="messagesGroup in messagesGroups">
        <div v-for="message in messagesGroup.messages" class="message">
          <div v-if="!message.typing" class="content">{{ message.content }}</div>
          <div v-else class="dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>

    <form v-show="isRemoteOnline" @submit.prevent="sendMessage">
      <FormInput
        id="chat-message"
        v-model="message"
        :label="$t('chat.message-label')"
        type="textarea"
        @input="handleInput"
        @keypress.enter.exact.prevent="sendMessage"
      />
    </form>
  </div>
</template>

<script>
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import AppNotificationItem from '@/components/AppNotification/AppNotificationItem';
  import FormInput from '@/components/Form/FormInput';
  import Draggabilly from "draggabilly";

  export default {
    name: 'Chat',
    components: { FormInput, AppNotificationItem, AutoHeightTextarea },
    props: {
      remoteUsername: {
        type: String,
        required: true,
      },
      appointmentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        message: '',
        isTyping: false,
      };
    },
    watch: {
      messagesGroups() {
        this.$nextTick(() => this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight);
      },
      isTyping(isTyping) {
        this.$store.dispatch(isTyping ? 'chatStartTyping' : 'chatStopTyping');
      },
    },
    computed: {
      isRemoteOnline() {
        return this.$store.state.chat.isRemoteOnline;
      },
      isRemoteTyping() {
        return this.$store.state.chat.isRemoteTyping;
      },
      messagesGroups() {
        const messages = [
          ...this.$store.state.chat.messages,
        ];

        if (this.isRemoteTyping) {
          messages.push({ type: 'remote', content: null, typing: true });
        }

        return messages.reduce((messagesGroups, message) => {
          const lastMessagesGroup = messagesGroups[messagesGroups.length - 1];

          if(!lastMessagesGroup || lastMessagesGroup.type !== message.type) {
            messagesGroups.push({ type: message.type, messages: [] });
          }

          messagesGroups[messagesGroups.length - 1].messages.push(message);

          return messagesGroups;
        }, []);
      },
    },
    methods: {
      sendMessage() {
        this.$store.dispatch('chatSendMessage', { message: this.message });
        this.message = '';
      },
      handleInput() {
        this.isTyping = true;

        if (this.typingTimeoutID) {
          window.clearTimeout(this.typingTimeoutID);
        }

        this.typingTimeoutID = this.$setTimeout(() => this.isTyping = false, 5000);
      },
    },
    mounted() {
      let draggableElems = document.querySelectorAll('.draggable');
      let draggies = []
      for ( let draggableElem of draggableElems ) {
        let draggie = new Draggabilly( draggableElem, {
            containment: true,
        });
        draggies.push( draggie );
      }
    }
  };
</script>

<style lang="scss" scoped>
  .remote-status {
    text-align: center;
    font-weight: 500;
    color: $errorColor;

    &.online {
      color: $successColor;
    }
  }

  .warning {
    font-size: 1.2rem;
    margin: 1rem 0;
  }

  .messages {
    max-height: 40rem;
    overflow: auto;
    margin: 1rem 0;
  }

  .messages-group {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }

    &.local {
      align-items: flex-end;
    }
  }

  .message {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    padding: 0.8rem 2rem;
    background-color: $chatLocalBackgroundColor;
    max-width: 40rem;
    min-height: 5rem;
    font-size: 1.6rem;
    font-weight: 300;

    &:first-child {
      margin-top: 0;
    }

    .local & {
      color: #FFFFFF;
      background-color: $chatRemoteBackgroundColor;
      border-top-left-radius: 2.5rem;
      border-bottom-left-radius: 2.5rem;

      &:first-child {
        border-top-right-radius: 2.5rem;
      }

      &:last-child {
        border-bottom-right-radius: 2.5rem;
      }
    }

    .remote & {
      border-top-right-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;

      &:first-child {
        border-top-left-radius: 2.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 2.5rem;
      }
    }
  }

  .content {
    white-space: pre-line;
  }

  @keyframes blink {
    0% {
      opacity: .05;
    }
    20% {
      opacity: .4;
    }
    100% {
      opacity: .05;
    }
  }

  .dots {
    display: inline-flex;
    width: 4rem;
    justify-content: space-between;
  }

  .dot {
    width: 1rem;
    height: 1rem;
    background-color: #FFFFFF;
    border-radius: 0.5rem;

    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: .2s;
    }

    &:nth-child(3) {
      animation-delay: .2s;
    }
  }

  .form-widget::v-deep .help {
    justify-content: flex-start;
  }
</style>
