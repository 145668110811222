var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      on: {
        "after-leave": function($event) {
          _vm.canBeClosed = true
        }
      }
    },
    [
      _vm.opened
        ? _c(
            "div",
            { staticClass: "app-panel" },
            [
              _vm.backPath
                ? _c("router-link", {
                    staticClass: "backdrop",
                    class: { transparent: _vm.transparent },
                    attrs: { to: _vm.backPath }
                  })
                : _c("div", {
                    staticClass: "backdrop",
                    class: { transparent: _vm.transparent },
                    on: { click: _vm.close }
                  }),
              _c(
                "div",
                { staticClass: "panel", style: { "--maxWidth": _vm.maxWidth } },
                [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$media.panel || this.opened,
                            expression: "$media.panel || this.opened"
                          }
                        ],
                        staticClass: "container-drag"
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$media.panel && this.opened,
                                expression: "$media.panel && this.opened"
                              }
                            ],
                            staticClass: "consultation-camera-local draggable"
                          },
                          [
                            _vm.localVideoStream
                              ? _c("video", {
                                  attrs: { autoplay: "" },
                                  domProps: { srcObject: _vm.remoteVideoStream }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "SectionTitle",
                          { staticClass: "panel-title" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.title) +
                                "\n                "
                            ),
                            _vm.backPath
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "close-panel",
                                    attrs: { to: _vm.backPath }
                                  },
                                  [
                                    _c("FontAwesomeIcon", {
                                      staticClass: "close-panel-icon",
                                      attrs: { icon: _vm.$icons.closePanel }
                                    })
                                  ],
                                  1
                                )
                              : _c("FontAwesomeIcon", {
                                  staticClass: "close-panel close-panel-icon",
                                  attrs: { icon: _vm.$icons.closePanel },
                                  on: { click: _vm.close }
                                })
                          ],
                          1
                        ),
                        _vm._t("panel")
                      ],
                      2
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }