<template>
  <div class="telemetry-panel">
    <div v-if="!doc2uStarted">
      <p v-html="$t('telemetry.info')"/>
      <ButtonGreen @click="toggleDoc2u" small rounded>{{ $t('telemetry.start') }}</ButtonGreen>
    </div>
    <div v-if="doc2uStarted" class="container-iframe">
      <div class="container-iframe-top">
        <div v-if="this.$store.state.observations.isFetching" class="container-spinner">
          <Spinner />
        </div>
        <div class="container-iframe__datablock" v-else>
          <ButtonGreen @click="createObservations"><p>{{ $t('telemetry.constantes-page.save-data') }}</p>
          </ButtonGreen>
          <div v-if="searchLength>0">
            <LastObservations :search-length="searchLength" :observations="this.$store.state.observations.search"/>
          </div>
          <div v-else>
            <p>{{ $t('telemetry.constantes-page.no-data') }}</p>
          </div>
        </div>
      </div>
      <iframe class="doc2u-content" :src="`https://app.doc2u.fr/room?t=${encounter.doc2uData.token}&practitionerMode=local&postMessage=true`"></iframe>
      <ButtonRed class="doc2u-button-finish" rounded small @click="terminate">{{ $t('telemetry.terminate') }}</ButtonRed>
    </div>
  </div>
</template>

<script>
  import SectionTitle from '../../SectionTitle';
  import SharedFiles from '../../SharedFiles';
  import ButtonGreen from "@/components/ButtonGreen";
  import ButtonRed from "@/components/ButtonRed";
  import LastObservations from "../../LastObservations";
  import Spinner from "../../Pharmacy/Spinner";
  import Draggabilly from 'draggabilly'

  export default {
    name: 'TelemetryPanel',
    components: {ButtonGreen, ButtonRed, SharedFiles, SectionTitle, LastObservations, Spinner },
    props: {
      encounter: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        data: [],
        dataTemperature: [],
        dataOxygen: [],
        dataCardiacRhythm: [],
        dataDiastolicPressure: [],
        dataSystolicPressure: [],
        searchLength: 0,
        doc2uStarted: false
      }
    },
    async mounted() {
      window.addEventListener("message",  (msg) =>  {
        if (msg.origin === "https://app.doc2u.fr") {
          if(msg.data.source==="oximeterManager") this.dataOxygen = msg.data;
          if(msg.data.source==="temperatureProcess") this.dataTemperature = msg.data;
          if(msg.data.source==="bloodPressureManager") this.dataTemperature = msg.data;
          this.data = {
            "dataTemperature" : this.dataTemperature,
            "dataOxygen" : this.dataOxygen,
            "dataCardiacRhythm" : this.dataCardiacRhythm,
            "dataDiastolicPressure" : this.dataDiastolicPressure,
            "dataSystolicPressure" : this.dataSystolicPressure,
          }
        }
      });
      this.getLastObservations()
        let draggableElems = document.querySelectorAll('.draggable');
        let draggies = []
        for ( let draggableElem of draggableElems ) {
          let draggie = new Draggabilly( draggableElem, {
              containment: true,
          });
          draggies.push( draggie );
        }
    },
    methods: {
      terminate() {
        this.$emit('terminate');
      },
      toggleDoc2u() {
        this.doc2uStarted = !this.doc2uStarted;
      },
      profileId() {
        return this.$store.state.patients.profile.id;
      },
      async createObservations() {
        if(this.data!==null) {
          let statusCode = 201;
          await this.$store.dispatch('observationsCreate', {
            patient: this.encounter.patient.id,
            data: this.data,
            encounter: null
          }).catch((error)=>{
            statusCode = error.response.status
          });
          if(statusCode >= 400) {
            this.$addError("Aucune mesure enregistrée.");
          } else {
            this.searchLength = null;
            await this.getLastObservations();
            this.$addSuccess("Vos mesures ont bien été enregistrées.");
          }
        }
      },
      async getLastObservations() {
        await this.$store.dispatch('observationsFetch', {
          patient: this.encounter.patient.id,
          lastObs: true,
          code: null
        })
        if(this.$store.state.observations.search) {
          this.searchLength = this.$store.state.observations.search.length;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.doc2u-content {
  border: 1px solid $borderColor;
  width: 100%;
  min-height: 70.5vh;
  border-radius: 3px;
  margin-top: 3rem;
  @include onMobile {
    height: 84.5vh;
  }
}
.doc2u-button-finish {
  display: block;
  margin: 1rem auto;
  @include onMobile {
    margin: 0.5rem auto 0;
  }
}
.container-iframe {
  .container-iframe-top {
    min-height: 90px;
    .container-iframe__datablock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin: 0 20px 20px 0;
      }
    }
  }
}
.container-spinner {
  margin: 0 20px 20px 0;
}
</style>
