var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "summary-interface" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          { staticClass: "summary" },
          [
            _c("SectionTitle", [
              _vm._v(" " + _vm._s(_vm.$t("summaryInterface.title")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("PractitionerCard", {
                  attrs: { practitioner: _vm.practitioner }
                }),
                _c("p", { staticClass: "start-at" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("date")(
                          _vm.appointment.startAt,
                          "dddd Do MMMM YYYY"
                        )
                      ) +
                      "\n          - " +
                      _vm._s(
                        _vm._f("date")(_vm.appointment.startAt, "HH[h]mm")
                      ) +
                      "\n        "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm._f("channel")(_vm.appointment.product.channel))
                  )
                ]),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("summaryInterface.advise"))
                  }
                })
              ],
              1
            ),
            _c("p", { staticClass: "amount-row" }, [
              _vm._v(_vm._s(_vm.$t("summaryInterface.amount"))),
              _c("span", { staticClass: "amount" }, [
                _vm._v(_vm._s(_vm._f("money")(_vm.totalPrice)))
              ])
            ]),
            _c("p", { staticClass: "content" }, [
              _c("small", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("summaryInterface.amount-info"))
                }
              })
            ]),
            _c(
              "portal",
              { attrs: { disabled: _vm.$media.desktop, to: "mobile-submit" } },
              [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit($event)
                      }
                    }
                  },
                  [
                    !_vm.selectedPaymentMethodId
                      ? _c(
                          "ButtonRed",
                          {
                            staticClass: "action",
                            attrs: { block: "", disabled: "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("summaryInterface.means-payment")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _c(
                          "ButtonGreen",
                          {
                            staticClass: "action",
                            attrs: {
                              busy: _vm.submitting,
                              icon: _vm.$icons.chevronRight,
                              block: "",
                              type: "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("summaryInterface.join-appointment")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "payment" }, [
          !_vm.paymentAccepted
            ? _c("div", { staticClass: "error" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("summaryInterface.payment-error.1")))
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("summaryInterface.payment-error.2")))
                ])
              ])
            : _c(
                "div",
                [
                  _c("GroupTitle", { attrs: { icon: _vm.$icons.creditCard } }, [
                    _vm._v(_vm._s(_vm.$t("summaryInterface.choose-payment")))
                  ]),
                  _c("p", { staticClass: "text_red" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("summaryInterface.payment-info.1")) +
                        "\n        "
                    )
                  ]),
                  _c("p", { staticClass: "text_red" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("summaryInterface.payment-info.2")) +
                        "\n        "
                    )
                  ]),
                  _c("CreditCards", {
                    attrs: { selectable: "" },
                    on: { select: _vm.handleSelectPaymentMethod },
                    model: {
                      value: _vm.selectedPaymentMethodId,
                      callback: function($$v) {
                        _vm.selectedPaymentMethodId = $$v
                      },
                      expression: "selectedPaymentMethodId"
                    }
                  })
                ],
                1
              )
        ]),
        _c("portal-target", { attrs: { name: "mobile-submit" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }