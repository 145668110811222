<template>
  <div class="select-wrapper">
    <slot />
    <div class="icon-container">
      <FontAwesomeIcon class="icon" :icon="$icons.selectIndicator" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectWrapper',
  };
</script>

<style lang="scss" scoped>
  .select-wrapper {
    position: relative;

    select {
      width: 100%;
      appearance: none;
      padding: 0.5rem 3rem 1rem 1rem;
    }
  }

  .icon-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.5rem;
    padding-right: 1rem;
  }
</style>
