var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-icon",
      class: {
        online: _vm.isRemoteOnline,
        "has-unread": _vm.hasUnreadMessage,
        small: _vm.small,
        dark: _vm.dark
      },
      on: { click: _vm.openChat }
    },
    [
      _c("FontAwesomeIcon", {
        staticClass: "icon",
        attrs: { icon: _vm.$icons.chat }
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasUnreadMessage,
              expression: "hasUnreadMessage"
            }
          ],
          staticClass: "unread"
        },
        [_vm._v("\n    " + _vm._s(_vm.unreadMessagesCount) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }