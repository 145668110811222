<template>
  <div class="waiting-room-interface">
    <FontAwesomeIcon class="icon" :icon="$icons.clock" @click="stopDevices" />
    <SectionTitle center>{{ $t('waitingRoomInterface.title') }}</SectionTitle>
    <span class="subtitle">{{ $t('waitingRoomInterface.subtitle') }}</span>

    <p>{{ $t('waitingRoomInterface.not-available', { name: practitionerFullName }) }}</p>
    <p>{{ $t('waitingRoomInterface.waiting-advice') }}</p>
    <div class="tips-container">
      <div class="control" @click="previousTip">
        <FontAwesomeIcon :icon="$icons.chevronLeft" />
      </div>
      <div class="tip">
        <h4>{{ $t(`waitingRoomInterface.tips.${currentTipIndex}.title`) }}</h4>
        <p>{{ $t(`waitingRoomInterface.tips.${currentTipIndex}.text`) }}</p>
      </div>
      <div class="control" @click="nextTip">
        <FontAwesomeIcon :icon="$icons.chevronRight" />
      </div>
      <FontAwesomeIcon class="heart-icon" :icon="$icons.heart" />
    </div>
    <div class="telemetry" @click="toggleTelemetryPanel">
      <FontAwesomeIcon class="helloscope-icon" :icon="$icons.doc2u" />
    </div>
    <AppPanel
        transparent
        :opened="isPanelVisible"
        :title="panelTitle"
        max-width="90rem"
        @close="visiblePanel = null"
    >
      <FilesPanel v-if="isFilePanelOpen" :appointment-id="appointmentId" :practitioner-id="practitionerId" />
      <TelemetryPanel v-if="isTelemetryPanelOpen" @terminate="visiblePanel = null" :encounter="encounter"
                      :appointment-id="appointmentId" :practitioner-id="practitionerId"/>
    </AppPanel>
  </div>
</template>

<script>
  import SectionTitle from '../SectionTitle';
  import UserAvatar from '../UserAvatar';
  import { formatCivility, formatFullName } from '../../helpers/format';
  import TelemetryPanel from './CallingInterface/TelemetryPanel';
  import AppPanel from "@/components/AppPanel";
  import FilesPanel from "@/components/Consultation/CallingInterface/FilesPanel";

  const PANEL = {
    FILES: 'FILES',
    TELEMETRY: 'TELEMETRY',
  };

  export default {
    name: 'WaitingRoomInterface',
    components: { SectionTitle, UserAvatar, TelemetryPanel, AppPanel, FilesPanel },
    props: {
      patientFullName: {
        type: String,
        required: true,
      },
      practitioner: {
        type: Object,
        required: true,
        default: {},
      },
      appointmentId: {
        type: String,
        required: true,
      },
      practitionerId: {
        type: String,
        required: true,
      },
      encounter: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentTipIndex: 1,
        visiblePanel: null,
      };
    },
    computed: {
      practitionerFullName() {
        return `${formatCivility(this.practitioner.civility)} ${formatFullName(this.practitioner)}`;
      },
      tips() {
        return [
          {
            title: this.$t('waitingRoomInterface.title'),
            text: this.$t('waitingRoomInterface.text'),
          },
          {
            title: this.$t('waitingRoomInterface.title'),
            text: this.$t('waitingRoomInterface.text'),
          },
          {
            title: this.$t('waitingRoomInterface.title'),
            text: this.$t('waitingRoomInterface.text')
          },
        ];
      },
      isPanelVisible() {
        return this.visiblePanel !== null;
      },
      isFilePanelOpen() {
        return this.visiblePanel === PANEL.FILES
      },
      isTelemetryPanelOpen() {
        return this.visiblePanel === PANEL.TELEMETRY
      },
      panelTitle() {
        switch (this.visiblePanel) {
          case PANEL.FILES:
            return 'Documents partagés';
          case PANEL.TELEMETRY:
            return this.$t('telemetry.title');
          default:
            return '';
        }
      }
    },
    methods: {
      stopDevices() {
        this.$store.dispatch('sdkStopDevices');
      },
      previousTip() {
        this.currentTipIndex -= 1;

        if (this.currentTipIndex < 0) {
          this.currentTipIndex = this.tips.length - 1;
        }
      },
      nextTip() {
        this.currentTipIndex += 1;

        if (this.currentTipIndex > this.tips.length - 1) {
          this.currentTipIndex = 0;
        }
      },
      toggleTelemetryPanel() {
        this.visiblePanel = this.visiblePanel === PANEL.TELEMETRY ? null : PANEL.TELEMETRY;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .waiting-room-interface {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #FFFFFF;
    padding: 2rem;
  }

  .icon {
    font-size: 5rem;
    color: $mainColor;
    margin-bottom: 3rem;
  }

  .section-title {
    margin: 0;
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
  }

  p {
    text-align: center;
    max-width: 30rem;
    margin: 3rem 0 0;
  }

  .tips-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  .control {
    display: flex;
    align-items: center;
    font-size: 3rem;
    padding: 0 2rem;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: $mainColor;
    }
  }

  .tip {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem;
    max-width: 40rem;
    min-height: 24rem;
    border: 1px solid $mainColor;
    text-align: center;

    @include onDesktop {
      padding: 0 5rem;
    }

    p {
      margin: 0;
    }

    h4 {
      color: $mainColor;
      font-size: 2rem;
    }
  }

  .heart-icon {
    position: absolute;
    left: 50%;
    bottom: -1.3rem;
    transform: translateX(-50%);
    font-size: 3rem;
    color: $mainColor;
  }

  .telemetry {
    background-color: #F46868;
    color: white;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    position: fixed;
    bottom: 100px;
    right: 20px;
    font-size: 3rem;
  }

  ::v-deep .content {
    @include onMobile {
      padding: 0.5rem;
    }
  }

  ::v-deep .panel-title {
    @include onMobile {
      margin-bottom: 0;
    }
  }
</style>
