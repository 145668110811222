var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    { attrs: { footer: false, header: false, nearest: false } },
    [
      _vm.isLoaded
        ? _c(
            "main",
            { staticClass: "consultation-page fullscreen" },
            [
              _vm.isStepWaitingRoom || _vm.isStepCalling
                ? _c("MediaSelector")
                : _vm._e(),
              _vm.isStepSummary
                ? _c("SummaryInterface", {
                    attrs: {
                      encounter: _vm.encounter,
                      "payment-accepted": _vm.practitioner.acceptPayment
                    },
                    on: {
                      start: function($event) {
                        _vm.showSummary = false
                      }
                    }
                  })
                : _vm.isStepError
                ? _c(
                    "ErrorInterface",
                    { attrs: { "error-code": _vm.errorCode } },
                    [_vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")]
                  )
                : !_vm.isNavigatorOnline
                ? _c(
                    "ErrorInterface",
                    {
                      attrs: {
                        title: _vm.$t("consultationPage.no-connexion"),
                        "error-code": "NAVIGATOR_OFFLINE"
                      }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("consultationPage.waiting-connexion")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm.isStepPostCalling
                ? _c("PostCallingInterface", {
                    attrs: { "appointment-id": _vm.appointmentId }
                  })
                : _vm.isStepTerminating
                ? _c("AppLoader", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("consultationPage.stop-consultation")) +
                        "\n    "
                    )
                  ])
                : _vm.isStepCountdown
                ? _c("CountdownInterface", {
                    attrs: {
                      "countdown-duration": 4000,
                      "patient-full-name": _vm.patientFullName,
                      practitioner: _vm.practitioner
                    },
                    on: { done: _vm.join }
                  })
                : _vm.isStepJoining
                ? _c("AppLoader", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("consultationPage.start-consultation")) +
                        "\n    "
                    )
                  ])
                : _vm.isStepWaitingRoom
                ? _c("WaitingRoomInterface", {
                    attrs: {
                      "patient-full-name": _vm.patientFullName,
                      practitioner: _vm.practitioner,
                      "appointment-id": _vm.appointmentId,
                      "practitioner-id": _vm.practitionerId,
                      encounter: _vm.encounter
                    }
                  })
                : _vm.isStepCalling
                ? _c("CallingInterface", {
                    attrs: {
                      "appointment-id": _vm.appointmentId,
                      "practitioner-id": _vm.practitionerId,
                      encounter: _vm.encounter
                    }
                  })
                : _c("AppLoader", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("consultationPage.loader")) +
                        "\n    "
                    )
                  ])
            ],
            1
          )
        : _vm._e(),
      _vm.isChatAvailable
        ? [
            !_vm.isStepCalling
              ? _c("ChatIcon", { attrs: { dark: _vm.isStepWaitingRoom } })
              : _vm._e(),
            _c(
              "AppPanel",
              {
                staticClass: "app-panel",
                attrs: { opened: _vm.isChatOpened, title: "Chat" },
                on: { close: _vm.closeChat }
              },
              [
                _c("Chat", {
                  attrs: {
                    "appointment-id": _vm.appointmentId,
                    "remote-username": _vm.practitionerFullName
                  }
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$media.panel && _vm.isChatOpened,
                        expression: "$media.panel && isChatOpened"
                      }
                    ],
                    staticClass: "consultation-camera-local draggable"
                  },
                  [
                    _vm.localVideoStream
                      ? _c("video", {
                          attrs: { autoplay: "" },
                          domProps: { srcObject: _vm.remoteVideoStream }
                        })
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("AppConfirmation", {
        ref: "leaveConsultation",
        attrs: { title: _vm.$t("consultationPage.stop") },
        on: {
          confirm: function(next) {
            return next()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }